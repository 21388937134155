class VideoModule {
  constructor () {
    this.hasVote = false;
    this.cacheDom();
    this.resizeIframe();
    this.events();
    this.initShare();
    this.initVote();
  }
  cacheDom () {
    this.$share = $(".VideoShare");
    this.$voteMessage = $(".VideoVoteMessage");
    this.$closeVoteMessage = $(".VideoVoteMessage i");
    this.$shareButton = $(".VideoBox__video .share");
    this.$voteButton = $(".VideoBox__video .vote");
    this.$video = $(".VideoBox__video iframe");
    this.$win = $(window);
  }
  resizeIframe () {
    const height = this.$video.width() / 16 * 9;
    this.$video.height(height);
  }
  events () {
    this.$win.on("resize", this.resizeIframe.bind(this));
    this.$win.on("orientationchange", this.resizeIframe.bind(this));
  }
  initShare () {
    this.$shareButton.click((e) => {
      e.preventDefault();
      this.$share.slideToggle(300);
    });
  }
  initVote () {
    this.$voteButton.click((e) => {
      e.preventDefault();
      if (this.hasVote) {
        return;
      }
      this.hasVote = true;
      this.$voteMessage.fadeIn(300);
    });
    this.$closeVoteMessage.click((e) => {
      e.preventDefault();
      this.$voteMessage.fadeOut(300);
    });
  }
}

if ($(".VideoBox").length > 0) {
  window.videoModule = new VideoModule();
}
