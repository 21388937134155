const jumps = [
  [".js-seo", "section.seo"],
  [".js-sem", "section.sem"],
  [".js-pms", "section.pms"],
  [".js-smm", "section.smm"],

  [".js-i-need", "section.feedback"],
  [".js-more-services", "section.what-we-can-offer"],
  [".js-get-in-touch", "section.feedback"],
  [".js-explore-more", "section.what-we-can-offer"],
];

for (let i in jumps) {
  $(jumps[i][0]).click((e) => {
    e.preventDefault();
    $([document.documentElement, document.body]).animate({
      scrollTop: $(jumps[i][1]).offset().top,
    }, 700);
  });
}

let isNumbersStarted = false;
function checkNumbers () {
  if (isNumbersStarted) {
    return;
  }
  if (!isElemOnScreen($(".numbers .number"), true)) {
    return;
  }
  isNumbersStarted = true;
  $(".numbers .number").each((i, node) => {
    const v = parseInt($(node).html());
    let b = 0;
    $(node).html(b + "");
    setInterval(() => {
      b += (v - b) * 0.02;
      $(node).html(Math.round(b) + "");
    }, 10);
  });
}
checkNumbers();
$(window).scroll(checkNumbers);

function isElemOnScreen ($el) {
  const top = $el.offset().top;
  const h = $el.outerHeight();
  const scrollTop = $(document).scrollTop();
  const screenH = window.innerHeight;
  const topSide = top - scrollTop;
  const bottomSide = topSide + h;
  if (topSide < screenH && bottomSide >= 0) {
    return true;
  }
  return false;
}
